<template>
	<div class="saving-screen fixed" v-if="isLoading">
		<span>Sayfa hazırlanıyor, lütfen bekleyin....</span>
	</div>
	<div class="saving-screen fixed" v-if="isCreating">
		<span>PDFler hazırlanıyor, lütfen bekleyin....</span>
	</div>
	<r-flex class="r-mb-16 page-header-C">
		<h3>Referans Düzenle</h3>
		<r-flex flx="4" align="flex-end" v-if="loaded">
			<r-btn
				bgcolor="#4422aa"
				@click="goQuestion(-1)"
				v-if="question.data.examNumber !== 1"
				:disabled="isLoading"
			>
				Önceki Soru
			</r-btn>
			<r-btn
				bgcolor="#4422aa"
				@click="goQuestion(1)"
				v-if="question.data.examNumber !== maxQuest"
				:disabled="isLoading"
			>
				Sonraki Soru
			</r-btn>
			<r-btn
				bgcolor="#ffaa11"
				@click="router.push('/referanslar/liste')"
				:disabled="isLoading"
			>
				Referans Listesine Dön
			</r-btn>
			<r-btn
				bgcolor="#aa6600"
				@click="createPDF"
				v-if="store.state.supers.indexOf(store.state.userrole) >= 0"
				:disabled="isLoading"
			>
				PDF Oluştur
			</r-btn>
		</r-flex>
	</r-flex>
	<r-flex
		align="center"
		class="cat-warning-C"
		v-if="loaded && store.state.usertype === 2"
	>
		Şu an
		<h3>&nbsp;{{ question.data.categoryName }}&nbsp;</h3>
		Sınav sorusuna referans ekliyorsunuz.
	</r-flex>
	<r-flex :class="[canFixed ? 'fixed-content' : '']">
		<r-flex flx="5" :class="['ref-col-left', canFixed ? 'fixed-col-left' : '']">
			<r-flex>
				<r-btn
					bgcolor="#aa6600"
					@click="AddReference"
					style="width: 100%"
					size="large"
				>
					Referans Ekle
				</r-btn>
			</r-flex>
			<r-flex
				:class="['references-list-C', canFixed ? 'fixed-references' : '']"
			>
				<r-data-list
					:headdata="headdata"
					:listdata="newDataList"
					:disabledrag="store.state.supers.indexOf(store.state.userrole) < 0"
					:opt="outeropt"
					v-if="listloaded"
				>
					<template v-slot:row="{ data, id }">
						<r-data-list
							:headdata="teacherdata"
							:listdata="data.data1"
							:disabledrag="
								store.state.supers.indexOf(store.state.userrole) < 0
							"
							:intitle="data.title"
							:opt="teacherListOpt"
							:lid="id"
						>
							<template v-slot:row="{ data, id }">
								<r-data-list
									:headdata="teacherdata"
									:listdata="data.data"
									:disabledrag="
										store.state.supers.indexOf(store.state.userrole) < 0 &&
										data.data[0].userId !== store.state.userid
									"
									:intitle="
										v => {
											return store.state.supers.indexOf(store.state.userrole) >=
												0 || data.data[0].userId === store.state.userid
												? data.title
												: ''
										}
									"
									:opt="teacherOpt"
									:customData="data"
									:lid="id"
								>
									<template v-slot:col2="{ datarow }">
										<r-data-list
											v-if="
												datarow.pages.length > 0 &&
												datarow.pages[0].detail.length > 0
											"
											:headdata="drawdata"
											:listdata="imgListData(datarow.pages[0].detail)"
											:disabledrag="
												store.state.supers.indexOf(store.state.userrole) < 0 &&
												datarow.userId !== store.state.userid
											"
											:opt="drawOpt"
											dragkey="referenceId"
											selectedkey="selected"
											:selectedstyle="{ backgroundColor: 'aquamarine' }"
										/>
									</template>
								</r-data-list>
							</template>
						</r-data-list>
					</template>
				</r-data-list>
			</r-flex>
		</r-flex>
		<r-flex flx="7" align="center" :class="[canFixed ? 'fixed-col-right' : '']">
			<references-pages
				v-if="newDataList.length > 0 && pagesloaded"
				:pagedata="newDataList"
				:qtype="questionType"
				:question="question.data"
				:imagelist="imagesList"
				@savenote="saveNote"
				@selectimage="selectImage"
				v-model:updated="pageUpdated"
			/>
			<references-temp-page
				v-if="newDataList.length === 0 && loaded"
				:qtype="questionType"
				:question="question.data"
			/>
		</r-flex>
	</r-flex>

	<r-panel
		v-if="imgPanel"
		v-model:isactive="imgPanel"
		pos="center"
		closepos="out-right"
		classes="r-referans-image-panel-C r-flx-dw-80"
		classcontent="r-flx-nop"
		width="50%"
	>
		<template v-slot:title>
			<r-flex class="r-flx-nop">
				<r-flex flx="4">
					<r-select
						label="Kitap"
						:data="notesList.data"
						txt="name"
						val="id"
						v-model="sdersnot"
						@change="dersNoteChange"
					/>
				</r-flex>
				<r-flex flx="4">
					<r-select
						:disabled="sdersnot ? false : true"
						label="Kitap"
						:data="fasikulCount"
						v-model="selectedFassicule"
						@change="fassiculeChange"
					/>
				</r-flex>
				<r-flex flx="4" v-if="selectedFassicule !== null">
					<r-select
						:disabled="sdersnot ? false : true"
						:label="'Kitap Sayfaları'"
						:data="fasikulPages['p' + selectedFassicule]"
						txt="p"
						val="p"
						v-model="selectedPage"
						@change="pageSelected"
					/>
				</r-flex>
			</r-flex>
		</template>
		<referans-image
			v-if="selectedPage !== null"
			:img="imgUrl"
			:data="imgData"
			v-model:close="imgPanel"
			v-model:saveme="saveme"
		/>
	</r-panel>
</template>
<script>
	import { ref, computed, watch, onMounted } from 'vue'
	import { useStore } from 'vuex'
	import { useRoute } from 'vue-router'
	import router from '@/router/'
	import { jsPDF } from 'jspdf'
	import html2canvas from 'html2canvas'
	import ReferansImage from './ReferansImage'
	import ReferencesPages from './ReferencesPages'
	import ReferencesTempPage from './ReferencesTempPage'

	export default {
		components: {
			ReferansImage,
			ReferencesPages,
			ReferencesTempPage,
		},
		setup() {
			const store = useStore()
			const route = useRoute()
			const notesList = ref({})
			const notesCatsList = ref({})
			const sdersnot = ref(null)
			const fasikulCount = ref([])
			const fasikulPages = ref({})
			const selectedFassicule = ref(null)
			const selectedPage = ref(null)
			const imgPanel = ref(false)
			const imgUrl = ref('')
			const imgData = ref({})
			const question = ref({})
			const loaded = ref(false)
			const listloaded = ref(false)
			const pagesloaded = ref(false)
			const pageUpdated = ref(false)
			const isLoading = ref(false)
			const saveData = ref({})
			const refDatas = ref({})
			const saveme = ref(false)
			const canFixed = ref(false)
			const dataList = ref([])
			const imagesList = ref([])
			const imageSelectData = ref({})
			const listScrollTop = ref(0)
			const pageScrollTop = ref(0)
			const isCreating = ref(false)
			const questionCount = ref(0)

			const questionType = computed(() => {
				if (store.state.usertype === 0) {
					if (parseInt(question.value.data.examNumber) <= store.state.tustemelq)
						return 'Temel Bilimler'
					else return 'Klinik Bilimler'
				} else if (store.state.usertype === 1) {
					if (parseInt(question.value.data.examNumber) <= store.state.dustemelq)
						return 'Temel Bilimler'
					else return 'Klinik Bilimler'
				} else if (store.state.usertype === 2) {
					return question.value.data.categoryName
				}
			})
			const maxQuest = computed(() => {
				if (store.state.usertype === 0) return store.state.tustotalq
				if (store.state.usertype === 1) return store.state.dustotalq
				if (store.state.usertype === 2) return questionCount.value
			})
			const newDataList = computed(() => {
				return dataList.value
			})
			const imgListData = d => {
				var newArr = []
				d.forEach(dat => {
					if (dat.isImg) newArr.push(dat)
				})
				return newArr
			}
			const newList = () => {
				listloaded.value = false
				pagesloaded.value = false
				if (refDatas.value.data.length > 0) isLoading.value = true
				pageUpdated.value = false
				var el = document.querySelector('.references-list-C')
				if (canFixed.value) listScrollTop.value = el.scrollTop
				pageScrollTop.value = window.scrollY
				setTimeout(() => {
					refDatas.value.data.splice(
						0,
						refDatas.value.data.length,
						...refDatas.value.data
					)
					refDatas.value.data.sort((a, b) => {
						return a.priority - b.priority
					})
					imagesList.value = []
					var newArr = []
					var newImgs = []
					var listKeys = []
					var ind = 0

					refDatas.value.data.forEach(itm => {
						if (listKeys.indexOf(itm.noteCategoryName) === -1) {
							listKeys.push(itm.noteCategoryName)
							let notcat = notesCatsList.value.data.filter(v => {
								return v.id === itm.noteCategoryId
							})[0]
							newArr.push({
								id: ind,
								title: itm.noteCategoryName,
								pagetitle: notcat.title,
								pagedesc: notcat.description,
								data: [itm],
							})
							newImgs.push([])
							ind++
						} else {
							newArr[listKeys.indexOf(itm.noteCategoryName)].data.push(itm)
						}
					})
					newArr.forEach((itm, i) => {
						var subKeys = []
						var indi = 0
						itm.data1 = []
						itm.data.forEach(dr => {
							if (subKeys.indexOf(dr.userId) === -1) {
								subKeys.push(dr.userId)
								itm.data1.push({
									id: indi,
									title: dr.username,
									userid: dr.userId,
									data: [dr],
								})

								indi++
							} else {
								itm.data1[subKeys.indexOf(dr.userId)].data.push(dr)
							}
							dr.pid = i
							delete itm.data
						})
						itm.data1.forEach((dat, m) => {
							dat.data.forEach((pag, n) => {
								if (pag.pages.length > 0) {
									if (pag.pages[0].detail.length > 0) {
										pag.pages[0].detail.forEach((drw, o) => {
											if (drw.isImg) {
												drw.iid = i
												drw.tid = m
												drw.pid = n
												drw.kid = o
												drw.userId = pag.userId
												drw.dname =
													// 'Fasikül ' +
													// pag.pages[0].fassiculeId +
													' Sayfa ' +
													drw.pageNo
												;(drw.url =
													'https://medoreferans.tusdata.com/referansnotlari/' +
													store.state.workFolder +
													'/edited_ref_img_' +
													store.state.usertype +
													'/' +
													question.value.data.categoryId +
													'/' +
													pag.lessonNoteId +
													'/' +
													drw.fileName +
													'.jpg'),
													newImgs[i].push(drw)
											}
										})
									}
								}
							})
						})
					})
					dataList.value.splice(0, dataList.value.length, ...newArr)
					imagesList.value.splice(0, imagesList.value.length, ...newImgs)
					listloaded.value = true
					pagesloaded.value = true
					setTimeout(() => {
						if (isLoading.value) isLoading.value = false
					}, 1500)
				}, 30)
			}

			const headdata = computed(() => {
				return [
					{
						style: { width: '100%' },
					},
				]
			})
			const outeropt = {
				datacount: false,
				header: false,
				dragend: v => {
					dragEnded(v)
				},
				class: {
					list: 'outer-data-list',
				},
			}
			const teacherdata = computed(() => {
				return [
					{
						style: { width: '40%' },
						key: 'lessonNoteName',
						cval: v => {
							return `${v.lessonNoteName} (${v.id})`
						},
					},
					{
						style: { width: '45%' },
					},
				]
			})
			const teacherListOpt = {
				datacount: false,
				collapse: true,
				dragend: (v, id) => {
					dragEndedTeacherList(v, id)
				},
				class: {
					list: 'teacher-list-data-list',
				},
			}
			const teacherOpt = {
				datacount: false,
				dragend: (v, id) => {
					dragEndedTeacherData(v, id)
				},
				intitle: {
					style: { width: '82%' },
				},
				class: {
					wrapper: 'wrapper-data-list',
				},
				buttons: {
					style: { width: '18%' },
					edit: {
						showf: v => {
							return (
								v.userId === store.state.userid ||
								store.state.supers.indexOf(store.state.userrole) >= 0
							)
						},
						type: 'out',
						func: d => {
							editFunc(d)
						},
					},
					delete: {
						showf: v => {
							return (
								v.userId === store.state.userid ||
								store.state.supers.indexOf(store.state.userrole) >= 0
							)
						},
						func: d => {
							deleteFunc(d)
						},
					},
					add: {
						show: store.state.supers.indexOf(store.state.userrole) >= 0,
						pos: 'head',
						func: d => {
							addFunc(d)
						},
					},
				},
			}
			const drawdata = computed(() => {
				return [
					{
						style: { width: '100%' },
						key: 'dname',
						title: 'Seçimler',
					},
				]
			})
			const drawOpt = {
				datacount: false,
				dragend: v => {
					dragEndedDraw(v)
				},
				row: {
					func: v => {
						drwRowClick(v)
					},
				},
				header: false,
			}
			const reSetPriority = async () => {
				var priorityList = []
				var i = 0
				dataList.value.forEach(itm => {
					itm.data1.forEach(itmnote => {
						itmnote.data.forEach(itmdata => {
							priorityList.push({
								referenceId: itmdata.id,
								Priority: i,
							})
							i++
						})
					})
				})

				await store.dispatch('postDatas', {
					api: 'references/prioritizereferences',
					data: priorityList,
				})
				imageSelectData.value = {}
				let list = await getReferenceDetail()
				refDatas.value = Object.assign({}, list)
				newList()
			}
			const drwRowClick = v => {
				var el = document.getElementById(
					'imgc-' +
						question.value.data.examNumber +
						'-' +
						v.row.iid +
						'-' +
						v.row.tid +
						'-' +
						v.row.pid +
						'-' +
						v.row.kid
				)
				el.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
					inline: 'center',
				})
			}
			const dragEnded = async d => {
				dataList.value.splice(0, dataList.value.length, ...d)
				await reSetPriority()
			}
			const dragEndedTeacherList = async (d, id) => {
				dataList.value[id].data1 = d
				await reSetPriority()
			}
			const dragEndedTeacherData = async (d, id) => {
				dataList.value[d[0].pid].data1[id].data = d
				await reSetPriority()
			}
			const dragEndedDraw = async d => {
				dataList.value[d[0].iid].data1[d[0].tid].data[
					d[0].pid
				].pages[0].detail = d
				await savePage(dataList.value[d[0].iid].data1[d[0].tid].data[d[0].pid])
			}
			const addFunc = async d => {
				sdersnot.value = null
				imgData.value.subcatname = questionType.value
				imgData.value.noteid = sdersnot.value
				imgData.value.catname = question.value.data.categoryName
				imgData.value.catid = question.value.data.categoryId
				imgData.value.examnumber = question.value.data.examNumber
				imgData.value.userid = d.userid
				saveData.value.userId = d.userid
				saveData.value.id = 0
				selectedFassicule.value = null
				selectedPage.value = null
				imgData.value.detail = []
				imgUrl.value = ''
				imgPanel.value = true
			}
			const deleteFunc = async d => {
				await store.dispatch('postDatas', {
					api: 'references/deletereference',
					data: { id: parseInt(d.row.id) },
				})
				refDatas.value = await getReferenceDetail()
				newList()
			}
			const getNotesList = async () => {
				var api =
					store.state.supers.indexOf(store.state.userrole) >= 0
						? 'notes/getallnotes'
						: 'notes/getnotesforuser'
				var params = {
					isActive: true,
				}
				if (store.state.supers.indexOf(store.state.userrole) < 0)
					params.userid = parseInt(store.state.userid)
				let list = await store.dispatch('getDatas', {
					api: api,
					params: params,
				})
				return list
			}
			const getQuestion = async () => {
				let list = await store.dispatch('getDatas', {
					api: 'questions/getquestion',
					params: {
						id: route.params.id,
					},
				})
				return list
			}
			const getReferenceDetail = async b => {
				var idi = b || route.params.id
				let list = await store.dispatch('getDatas', {
					api: 'references/getreferencesforquestion',
					params: {
						questionid: idi,
						isImg: false,
					},
				})
				return list
			}
			const getNoteCatsList = async () => {
				let list = await store.dispatch('getDatas', {
					api: 'notes/getnotecategories',
				})
				return list
			}

			const goQuestion = async i => {
				if (!isLoading.value) {
					var num = parseInt(question.value.data.examNumber) + i
					let api = 'references/getquestionbyexamnumber'
					let params = {
						examnumber: num,
						workmonth: store.state.workMonth,
						workyear: store.state.workYear,
					}
					if (store.state.usertype === 2) {
						api = 'references/getquestionbyexamnumberandcategory'
						params.categoryid = question.value.data.categoryId
					}
					let list = await store.dispatch('getDatas', {
						api: api,
						params: params,
					})

					router.push('/referanslar/duzenle/' + list.data.id)
					question.value = Object.assign({}, list)
					saveData.value.branchId = parseInt(question.value.data.categoryId)
					saveData.value.questionId = parseInt(list.data.id)
					saveData.value.pages = []
					refDatas.value = await getReferenceDetail(list.data.id)
					newList()
				}
			}
			const getQuestionCount = async () => {
				let list = await store.dispatch('getDatas', {
					api: 'categories/getquestioncount',
					params: {
						categoryid: question.value.data.categoryId,
					},
				})
				return list.data
			}

			const AddReference = () => {
				sdersnot.value = null
				imgData.value.subcatname = questionType.value
				imgData.value.noteid = sdersnot.value
				imgData.value.catname = question.value.data.categoryName
				imgData.value.catid = question.value.data.categoryId
				imgData.value.examnumber = question.value.data.examNumber
				imgData.value.userid = store.state.userid
				saveData.value.userId = store.state.userid
				saveData.value.id = 0
				selectedFassicule.value = null
				selectedPage.value = null
				imgData.value.detail = []
				imgUrl.value = ''
				imgPanel.value = true
			}
			const editFunc = async d => {
				sdersnot.value = d.row.lessonNoteId
				saveData.value.id = d.row.id
				saveData.value.lessonNoteId = d.row.lessonNoteId
				saveData.value.noteCategoryId = d.row.noteCategoryId
				let note = notesList.value.data.filter(v => {
					return v.id === d.row.lessonNoteId
				})[0]
				createFasikuls(note)
				selectedFassicule.value = d.row.pages[0].fassiculeId
				imgData.value.fassiculeId = d.row.pages[0].fassiculeId
				selectedPage.value = d.row.pages[0].pageNo
				imgData.value.notename = note.name
				imgData.value.imgcatid = note.categoryId
				imgData.value.pageNo = d.row.pages[0].pageNo
				imgData.value.subcatname = questionType.value
				imgData.value.noteid = sdersnot.value
				imgData.value.catname = question.value.data.categoryName
				imgData.value.catid = question.value.data.categoryId
				imgData.value.examnumber = question.value.data.examNumber
				imgData.value.detail = d.row.pages[0].detail
				imgData.value.userid = d.row.userId
				imgData.value.refid = d.row.pages[0].referenceId
				saveData.value.userId = d.row.userId
				let imgurl =
					'https://medoreferans.tusdata.com/referansnotlari/' +
					store.state.workFolder +
					'/ref_img_' +
					store.state.usertype +
					'/' +
					imgData.value.imgcatid +
					'/' +
					sdersnot.value +
					'/' +
					d.row.pages[0].pageNo.toString().padStart(3, '0') +
					'.jpg'
				imgUrl.value = imgurl
				imgPanel.value = true
			}
			const dersNoteChange = d => {
				imgData.value.notename = d.name
				imgData.value.imgcatid = d.categoryId
				saveData.value.lessonNoteId = d.id
				saveData.value.noteCategoryId = d.categoryTypeId
				selectedFassicule.value = null
				selectedPage.value = null
				fasikulCount.value = []
				saveData.value.pages = [{}]
				setTimeout(() => {
					createFasikuls(d)
				}, 100)
			}
			const createFasikuls = d => {
				fasikulCount.value = []
				var pages = d.pageNo.split('#')
				for (let i = 0; i < d.fassiculeCount; i++) {
					fasikulCount.value.push(i + 1)
					let p = pages[i].split('-')
					fasikulPages.value['p' + (i + 1)] = []
					for (let j = parseInt(p[0]); j <= parseInt(p[1]); j++) {
						fasikulPages.value['p' + (i + 1)].push({ f: i + 1, p: j })
					}
				}
			}
			const fassiculeChange = d => {
				saveData.value.pages[0].fassiculeId = d
				selectedPage.value = null
			}
			const pageSelected = d => {
				imgUrl.value = ''
				imgData.value.fassiculeId = d.f
				saveData.value.pages[0].pageNo = d.p
				imgData.value.pageNo = d.p
				imgData.value.subcatname = questionType.value
				imgData.value.noteid = sdersnot.value
				imgData.value.catname = question.value.data.categoryName
				imgData.value.catid = question.value.data.categoryId
				imgData.value.examnumber = question.value.data.examNumber
				imgData.value.detail = []
				let imgurl =
					'https://medoreferans.tusdata.com/referansnotlari/' +
					store.state.workFolder +
					'/ref_img_' +
					store.state.usertype +
					'/' +
					imgData.value.imgcatid +
					'/' +
					sdersnot.value +
					'/' +
					d.p.toString().padStart(3, '0') +
					'.jpg'
				imgUrl.value = imgurl
			}
			const saveNote = async (d, t) => {
				dataList.value[d.iid].data1[d.tid].data[d.pid].pages[0].detail[
					d.kid
				].note = t
				dataList.value[d.iid].data1[d.tid].data[d.pid].pages[0].detail[d.kid] = d
				await savePage(dataList.value[d.iid].data1[d.tid].data[d.pid])
			}
			const selectImage = (i, j, k, l) => {
				if (imageSelectData.value.i !== undefined) {
					dataList.value[imageSelectData.value.i].data1[
						imageSelectData.value.j
					].data[imageSelectData.value.k].pages[0].detail[
						imageSelectData.value.l
					].selected = false
				}
				dataList.value[i].data1[j].data[k].pages[0].detail[l].selected = true
				imageSelectData.value = { i: i, j: j, k: k, l: l }
			}
			const savePage = async d => {
				var data = {}
				if (d) data = Object.assign({}, d)
				else data = Object.assign({}, saveData.value)
				data.pages.forEach(itm => {
					delete itm.id
					delete itm.originalUrl
					delete itm.url
					itm.detail.forEach(sub => {
						delete sub.id
						delete sub.iid
						delete sub.tid
						delete sub.pid
					})
				})
				delete data.questionChoices
				delete data.lessonNoteName
				delete data.noteCategoryName
				delete data.questionAnswer
				delete data.questionDetail
				delete data.pid
				delete data.username
				delete data.hasPermission
				delete data.referenceNote
				await store.dispatch('postDatas', {
					api: 'references/addreference',
					data: data,
				})
				imageSelectData.value = {}
				let list = await getReferenceDetail()
				refDatas.value = Object.assign({}, list)
				newList()
			}
			const createPDF = () => {
				isCreating.value = true
				setTimeout(() => {
					const doc = new jsPDF('p', 'px', [1000, 1415])

					var els = document.getElementsByClassName('pdf-screen-W-save')
					var cnt = 0
					var imgCnt = 0
					var imgC = {}
					var intC = {}
					els.forEach(async (el, i) => {
						await html2canvas(el, {
							scale: 2.535,
							scrollX: 0,
							scrollY: 0,
							width: 700,
						}).then(function (canvas) {
							imgC['im' + i] = canvas.toDataURL('image/jpeg')
							intC['i' + i] = setInterval(() => {
								if (imgC['im' + i] !== '') {
									clearInterval(intC['i' + i])
									cnt++
								}
							}, 300)
						})
					})
					var saveImgInt = setInterval(() => {
						if (cnt === els.length) {
							clearInterval(saveImgInt)
							for (let e = 0; e < els.length; e++) {
								doc.addImage(imgC['im' + e], 'JPEG', 0, 0)
								imgCnt++
								if (imgCnt !== els.length) doc.addPage()
								delete imgC['im' + e]
							}
						}
					}, 1000)
					var int = setInterval(() => {
						if (imgCnt === els.length) {
							clearInterval(int)
							doc.save(
								store.state.workFolder +
									'_' +
									question.value.data.examNumber +
									'.pdf'
							)
							isCreating.value = false
						}
					}, 600)
				}, 50)
			}
			watch(
				() => saveme.value,
				() => {
					if (saveme.value) {
						if (!saveData.value.pages[0]) saveData.value.pages.push({})
						saveData.value.pages[0].fileName = imgData.value.fileName
						saveData.value.pages[0].detail = imgData.value.detail
						saveData.value.pages[0].fassiculeId = imgData.value.fassiculeId
						saveData.value.pages[0].referenceId = imgData.value.refid
						saveData.value.pages[0].pageNo = imgData.value.pageNo
						savePage()
						saveme.value = false
					}
				}
			)
			watch(
				() => store.state.scrollY,
				() => {
					canFixed.value = store.state.scrollY >= 180 ? true : false
				}
			)
			watch(
				() => imgPanel.value,
				async () => {
					if (imgPanel.value === false) {
						let data = await getReferenceDetail()
						refDatas.value = Object.assign({}, data)
						newList()
					}
				}
			)
			watch(
				() => pageUpdated.value,
				() => {
					if (pageUpdated.value) {
						var el = document.querySelector('.references-list-C')
						setTimeout(() => {
							window.scrollTo(0, pageScrollTop.value)
						}, 200)
						setTimeout(() => {
							el.scrollTo(0, listScrollTop.value)
							isLoading.value = false
						}, 350)
					}
				}
			)
			onMounted(async () => {
				await store.dispatch('getWorkFolder')
				question.value = await getQuestion()
				notesList.value = await getNotesList()
				notesCatsList.value = await getNoteCatsList()
				refDatas.value = await getReferenceDetail()

				questionCount.value = await getQuestionCount()

				if (refDatas.value.data.length > 0) {
					isLoading.value = true
					newList()
				}
				saveData.value.userId = store.state.userid
				saveData.value.branchId = parseInt(question.value.data.categoryId)
				saveData.value.questionId = parseInt(route.params.id)
				saveData.value.pages = []
				loaded.value = true
			})
			return {
				notesList,
				selectedFassicule,
				sdersnot,
				dersNoteChange,
				fasikulCount,
				fasikulPages,
				selectedPage,
				imgPanel,
				imgData,
				imgUrl,
				question,
				loaded,
				pageSelected,
				saveData,
				store,
				AddReference,
				fassiculeChange,
				headdata,
				refDatas,
				teacherdata,
				drawdata,
				saveme,
				outeropt,
				teacherOpt,
				teacherListOpt,
				drawOpt,
				canFixed,
				dataList,
				imagesList,
				newDataList,
				questionType,
				saveNote,
				selectImage,
				createPDF,
				goQuestion,
				maxQuest,
				router,
				listloaded,
				pagesloaded,
				imgListData,
				isLoading,
				pageUpdated,
				isCreating,
			}
		},
	}
</script>
<style scoped>
	.cat-warning-C {
		background-color: crimson;
		color: #fff;
		border-radius: 3px;
	}
</style>