<template><r-data-list
			:headdata="headdata"
			:listdata="listData"
			:opt="opts"
			v-if="loaded"
			:disabledrag="false"
			dragkey="priority"
			><template v-slot:rowextrabtn="{datarow}">
				<r-btn @click="openReferences(datarow)">Düzenle</r-btn>
			</template>
		</r-data-list>
</template>
<script>
import {onBeforeMount, ref, computed} from 'vue'
import {useStore} from 'vuex'
import router from '@/router'
import {useRoute} from 'vue-router'
import {mxGetValue} from '@/mix/global'
export default {
	setup() {
		const store = useStore()
		const route = useRoute()
		const listData = ref({})
		const subListInfo = ref({})
		const categoryList = ref({})
		const loaded = ref(false)
		const headdata = computed(() => {
			return [
				{
					title: 'Ref. No',
					style: {width: '5%'},
					key: 'id',
				},
				{
					title: 'Branş',
					style: {width: '10%', justifyContent: 'center'},
					cval: d => {
						return mxGetValue(
							categoryList.value.data,
							'id',
							d.branchId,
							'title'
						)
					},
				},
				{
					title: 'S. Soru No',
					style: {width: '5%'},
					key: 'questionId',
					html: true,
				},
				{
					title: 'Ders Notu',
					style: {width: '25%'},
					key: 'lessonNoteId',
				},
				{
					title: 'Fasikül',
					style: {width: '5%', justifyContent: 'center'},
					cval: d => {
						if(d.pages.length > 0) return d.pages[0].fassiculeId
						else return ''
					},
				},
				{
					title: 'Sayfa No',
					style: {width: '10%', justifyContent: 'center'},
					cval: d => {
						let str = ''
						d.pages.forEach((el, i) => {
							str += el.pageNo + (i === d.pages.length - 1 ? '' : ', ')
						})
						return str
					},
				},
				{
					title: 'Resimler',
					style: {width: '30%', justifyContent: 'center'},
					cval: d => {
						let str = ''
						d.pages.forEach((el, i) => {
							str += el.fileName + (i === d.pages.length - 1 ? '' : ', ')
						})
						return str
					},
				},
			]
		})
		const opts = ref({
			title: 'Sorular',
			dragend: d => {
					dragEnd(d)
				},
			buttons: {
				style: {width: '10%'},
				delete: {
					show:true,
					func: d => {
						deleteFunc(d)
					},
				},
				add: {
					show:true,
					func: d => {
						addFunc(d)
					},
				},
			},
		})
		const dragEnd = async d => {
			var send = []
			d.forEach((itm,i) => {
				itm.priority = i
				send.push({referenceId:itm.id, priority:i})
			})
			await store.dispatch('postDatas', {
				api: 'references/prioritizereferences',
				data: send,
			})
		}
		const getList = async () => {
			let list = await store.dispatch('getDatas', {
				api: 'references/getreferencesforquestion',
				params: {
					questionid: route.params.id,
				},
			})
			list.data.sort((a, b) => a.priority - b.priority)
			return list
		}
		const getCatList = async () => {
			let list = await store.dispatch('getDatas', {
				api: 'categories/getsubcategories',
				params: {
					pid: 0,
				},
			})
			return list
		}
		const deleteFunc = async d => {
			await store.dispatch('postDatas', {
				api: 'references/deletereference',
				data: {id: parseInt(d.row.id)},
			})
			listData.value = await getList()
		}
		const addFunc = ()=> {
			router.push('/referanslar/ekle/' + route.params.id+'/0')
		}
		const openReferences = async d => {
			router.push('/referanslar/duzenle/' + route.params.id+'/'+d.id)
		}
		onBeforeMount(async () => {
			categoryList.value = await getCatList()
			let arr = await getList()
			listData.value = arr.data
			loaded.value = true
		})
		return {
			headdata,
			opts,
			listData,
			openReferences,
			subListInfo,
			loaded,
		}
	},
}
</script>
