<template>
	<r-data-list
		:headdata="headdata"
		:listdata="filteredList"
		:opt="opts"
		:searchfilter="searchfilter"
		v-model:qu="q"
		v-if="loaded"
	>
		<template v-slot:titleextrabuttons>
			<r-select
				v-if="store.state.usertype === 2 && store.state.supers.indexOf(store.state.userrole) > 0"
				v-model="store.state.selectedcatid"
				label="Kategoriler"
				:data="catList.data"
				txt="title"
				val="id"
				width="25%"
				@change="GetCategoryQuestions"
				style="color: rgb(17, 17, 17)"
			>
				<template v-slot:itemtxt="{ data }">
					{{ data.id + '_' + data.title }}
				</template>
			</r-select>
			<r-select
				v-model="selectedreference"
				label="Referans Durumu"
				:data="referenceSelect"
				txt="txt"
				val="val"
				width="20%"
			/>
			<r-input v-model="qs" label="Soruya Git" width="15%" />
		</template>
		<template v-slot:rowextrabtn="{ datarow }">
			<r-btn @click="editReferences(datarow)">Referans Ekle / Düzenle</r-btn>
		</template>
		<template v-slot:col5="{ datarow }">
			<span
				class="r-check-ok small"
				v-if="datarow.isReferenced === true"
			></span>
			<span class="r-check-no small" v-else></span>
		</template>
	</r-data-list>
</template>
<script>
	import { onBeforeMount, ref, computed } from 'vue'
	import { useStore } from 'vuex'
	import router from '@/router'
	import { trToUpper } from '@/mix/global'
	export default {
		setup() {
			const store = useStore()
			const listData = ref({})
			const loaded = ref(false)
			const q = ref('')
			const qs = ref('')
			const catList = ref({})
			const selectedreference = ref(null)
			const referenceSelect = ref([
				{ txt: 'Tümü', val: null },
				{ txt: 'Referanslı', val: true },
				{ txt: 'Referanssız', val: false },
			])
			const selectfilter = v => {
				return v.isReferenced === selectedreference.value
			}
			const goquestionfilter = v => {
				return trToUpper(v.examNumber).includes(trToUpper(qs.value))
			}

			const searchfilter = v => {
				return (
					trToUpper(v.description).includes(trToUpper(q.value)) ||
					trToUpper(v.detail).includes(trToUpper(q.value)) ||
					trToUpper(v.case).includes(trToUpper(q.value)) ||
					trToUpper(v.number).includes(trToUpper(q.value)) ||
					trToUpper(v.examNumber).includes(trToUpper(q.value)) ||
					trToUpper(v.choiceList).includes(trToUpper(q.value))
				)
			}
			const filteredList = computed(() => {
				if (listData.value.data) {
					listData.value.data.sort((a, b) => {
						return parseInt(a.examNumber) - parseInt(b.examNumber)
					})
					if (selectedreference.value === null) {
						return listData.value.data.filter(goquestionfilter)
					} else {
						return listData.value.data
							.filter(goquestionfilter)
							.filter(selectfilter)
					}
				}
			})
			const headdata = computed(() => {
				return [
					{
						title: 'Branş',
						style: { width: '12%' },
						key: 'categoryName',
					},
					{
						title: 'Sınav Soru No',
						style: { width: '10%', justifyContent: 'center' },
						key: 'examNumber',
					},
					{
						title: 'Soru',
						style: { width: '30%' },
						key: 'detail',
						cval: v => {
							let str = ''
							if (v.isCase) str += v.case
							str += v.detail
							return str
						},
						html: true,
					},
					{
						title: 'Şıklar',
						style: { width: '25%' },
						html: true,
						cval: v => {
							var str = ''
							v.choiceList.forEach((d, i) => {
								if (d.startsWith('<p>')) {
									str += `${d.slice(0, 3)}<strong>${
										store.state.choices[i]
									}) </strong>${d.slice(3)}`
								} else {
									str += '<strong>' + store.state.choices[i] + ': </strong>' + d
									if (i !== v.choiceList.length - 1) str += '<br>'
								}
							})
							return str
						},
					},
					{
						title: 'Referanslı mı?',
						style: { width: '10%', justifyContent: 'center' },
						type: 'checkbox',
						key: 'title',
					},
				]
			})
			const opts = ref({
				title: 'Sorular',
				buttons: {
					style: { width: '13%' },
				},
			})
			const getList = async () => {
				let list = await store.dispatch('getDatas', {
					api: 'questions/getallquestions',
					params: {
						page: 1,
						size: 300,
						order: 1,
						workmonth: store.state.workMonth,
						workyear: store.state.workYear,
					},
				})
				return list
			}
			const getQuestionList = async id => {
				let list = await store.dispatch('getDatas', {
					api: 'questions/GetQuestionsByCategory',
					params: {
						categoryid: id,
						subcatid: 0,
						page: 1,
						size: 300,
						order: 1,
						workmonth: store.state.workMonth,
						workyear: store.state.workYear,
					},
				})
				return list
			}
			const GetCategoryQuestions = async d => {
				listData.value = await getQuestionList(d.id)
			}
			const getCatList = async () => {
				let list = await store.dispatch('getDatas', {
					api: 'categories/getsubcategories',
					params: {
						pid: 0,
					},
				})

				return list
			}
			const editReferences = async d => {
				router.push('/referanslar/duzenle/' + d.id)
			}
			onBeforeMount(async () => {
				await store.dispatch('getWorkFolder')

				catList.value = await getCatList()

				if (store.state.usertype === 0 || store.state.usertype === 1) {
					listData.value = await getList()
				} else if (store.state.usertype === 2) {
					if (store.state.selectedcatid === null) {
						if (store.state.usercatid !== null)
							store.state.selectedcatid = store.state.usercatid
						else store.state.selectedcatid = catList.value.data[0].id

						if (!catList.value.data.some(v => v.id === store.state.selectedcatid))
							store.state.selectedcatid = catList.value.data[0].id
					}

					listData.value = await getQuestionList(store.state.selectedcatid)
				}
				loaded.value = true
			})
			return {
				headdata,
				opts,
				editReferences,
				loaded,
				searchfilter,
				q,
				qs,
				selectedreference,
				referenceSelect,
				filteredList,
				catList,
				GetCategoryQuestions,
				store,
			}
		},
	}
</script>
