<template>
	<div
		class="outer-page-W"
	>
		<template v-if="loaded">
			<div class="pdf-screen-W">
				<div class="pdf-screen-C">
					<r-flex class="pdf-screen-question-C">
						<span class="original-question">
							Orijinal Soru: {{ qtype }} {{ question.examNumber }}
						</span>
						<span class="question-osym"
							>{{ pdftelif }}</span
						>
						<r-flex class="r-flx-nop question-content">
							<r-flex flx="1" class="r-flx-nop" style="width: 3%;flex:0 3%;">
								<strong>{{ question.examNumber }}.</strong>
							</r-flex>
							<r-flex flx="11" class="r-flx-nop">
								<r-flex
									v-if="question.isCase"
									v-html="question.case"
									class="r-flx-nop case-data"
								></r-flex>
								<r-flex
									v-html="question.detail"
									class="r-flx-nop question-data"
								></r-flex>
								<r-flex>
									<r-flex
										class="r-flx-nop"
										v-for="(choice, i) in question.choiceList"
										:key="i"
									>
										<strong>{{ store.state.choices[i] }})&nbsp; </strong>
										<div v-html="choice"></div> </r-flex
								></r-flex>
							</r-flex>
							<r-flex>
								<strong>Doğru Cevap: </strong> {{ question.answer }}
							</r-flex>
						</r-flex>
					</r-flex>
					<r-flex class="pdf-view-content-C r-flx-nop">
						<r-flex class="pdf-right-color" flx="3"></r-flex>
					</r-flex>
					<r-flex align="center" class="pdf-footer">
						{{ pdffooter }}
					</r-flex>
				</div>
			</div>
		</template>
	</div>
	<div
		class="outer-page-W-save"
	>
		<template v-if="loaded">
			<div class="pdf-screen-W-save">
				<div class="pdf-screen-C-save">
					<r-flex class="pdf-screen-question-C-save">
						<span class="original-question">
							Orijinal Soru: {{ qtype }} {{ question.examNumber }}
						</span>
						<span class="question-osym"
							>{{ pdftelif }}</span
						>
						<r-flex class="r-flx-nop question-content">
							<r-flex flx="1" class="r-flx-nop" style="width: 3%;flex:0 3%;">
								<strong>{{ question.examNumber }}.</strong>
							</r-flex>
							<r-flex flx="11" class="r-flx-nop">
								<r-flex
									v-if="question.isCase"
									v-html="question.case"
									class="r-flx-nop case-data"
								></r-flex>
								<r-flex
									v-html="question.detail"
									class="r-flx-nop question-data"
								></r-flex>
								<r-flex>
									<r-flex
										class="r-flx-nop"
										v-for="(choice, i) in question.choiceList"
										:key="i"
									>
										<strong>{{ store.state.choices[i] }})&nbsp; </strong>
										<div v-html="choice"></div> </r-flex
								></r-flex>
							</r-flex>
							<r-flex>
								<strong>Doğru Cevap: </strong> {{ question.answer }}
							</r-flex>
						</r-flex>
					</r-flex>
					<r-flex class="pdf-view-content-C r-flx-nop">
						<r-flex class="pdf-right-color" flx="3"></r-flex>
					</r-flex>
					<r-flex align="center" class="pdf-footer-save">
						{{ pdffooter }}
					</r-flex>
				</div>
			</div>
		</template>
	</div>
</template>
<script>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
export default {
	props: {
		qtype: String,
		question: Object,
	},
	setup() {
		const store = useStore()
		const loaded = ref(true)
		const pdffooter = ref('')
		const pdftelif = ref('')

		const getSettings = async () => {
			let list = await store.dispatch('getDatas', {
				api: 'settings/getsettingsforuser',
			})
			return list
		}

		const openSettings = async () => {
			let data = await getSettings()
			data.data.forEach(o => {
				if (o.key === 'pdffooter') pdffooter.value = o.value
				if (o.key === 'pdftelif') pdftelif.value = o.value
			})
		}
		onMounted(() => {
			openSettings()
		})

		return {
			store,
			loaded,
			pdffooter,
			pdftelif
		}
	},
}
</script>
